import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col items-center" }
const _hoisted_2 = {
  key: 0,
  class: "banner banner-danger mb-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Btn = _resolveComponent("Btn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.popupBlocked)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t("misc.popup_blocked")), 1)
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_Btn, {
      variant: 'secondary',
      loading: _ctx.loading,
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.grantScopes()))
    }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default")
      ]),
      _: 3
    }, 8, ["loading"])
  ]))
}